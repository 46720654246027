import { Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import LandingNavbar from "../../components/LandingNavbar";
import {
    StyledDensityHardwareContainer,
    StyledLandingBoldParagraph,
    StyledLandingPageWrapper,
    StyledLandingVideoWrapper,
} from "./landing.styled";
import useWindowDimensions from "../../utils/hooks/windowDimensionsHook";
import Footer from "../../components/Landing/Footer";
import { useDispatch } from "react-redux";
import { InfoPanel } from "../../components/Info";
import TEXTS from "./text";
import ModalVideo from "react-modal-video";
import NewCoverImage from "../../assets/new-cover-image.png";
import RectSetupStepOne from "../../assets/rec-setup-step-one.svg";
import RectSetupStepTwo from "../../assets/rec-setup-step-two.svg";
import RectSetupStepThree from "../../assets/rec-setup-step-three.svg";
import RectSetupStepFour from "../../assets/rec-setup-step-four.svg";
import RectSetupStepFive from "../../assets/rec-setup-step-five.svg";
import RectSetupStepSix from "../../assets/rec-setup-step-six.svg";
import RectSetupStepSeven from "../../assets/rec-setup-step-seven.svg";
import RectSetupStepEight from "../../assets/rec-setup-step-eight.svg";
import RectSetupStepFinal from "../../assets/rec-setup-final-step.svg";
import RetrievingSetsStepOne from "../../assets/retrieving-sets-step-one.svg";
import RetrievingSetsStepTwo from "../../assets/retrieving-sets-step-two.svg";
import RetrievingSetsStepThree from "../../assets/retrieving-sets-step-three.svg";
import RetrievingSetsStepFour from "../../assets/retrieving-sets-step-four.svg";
import RetrievingSetsStepFive from "../../assets/retrieving-sets-step-five.svg";
import OpenTheSet from '../../assets/open-the-set.svg';
import PlayIcon from "../../assets/Pause Controls.svg";
import FullSetup from "../../assets/rec-full-setup.svg";
import Mixer from "../../assets/mixer.svg";
import DJBooth from "../../assets/dj-booth.svg";
import MultiPort from "../../assets/multi-port.svg";
import Info from "../../assets/info.svg";
import InfoShowcase from "../../components/Info/InfoShowcase";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import { api } from "../../api/base";
import { decodeToken } from "react-jwt";
import { analyticsEvent } from "../../redux/actions/analytics";
import Step from "../../components/Info/InfoPanel/Step";
import FaqCard from "../../components/Info/FaqCard";

const RecSetupPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const contentLoaded = Boolean(localStorage.getItem("content-loaded"));
    const [loading, setLoading] = useState(!contentLoaded);
    const videoRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatch(
            analyticsEvent("EVENT", "REC Setup Page Visited", {
                page: "REC Setup Page",
            }),
        );
    }, []);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "scroll";
        }
        return () => {
            document.body.style.overflowY = "scroll";
        };
    }, [isOpen]);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            if (currentScrollPos > prevScrollPos) {
                setVisible(true);
            } else {
                setVisible(false);
            }

            setPrevScrollPos(currentScrollPos);
        };
        window.addEventListener("scroll", handleScroll, true);

        if (loading) {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 1500);

            setTimeout(() => {
                setLoading(false);
                localStorage.setItem("content-loaded", "true");
            }, 2500);
        }

        return () => window.removeEventListener("scroll", handleScroll, true);
    }, [dispatch, loading, prevScrollPos]);

    const buyHardwarePlan = async (): Promise<void> => {
        if (!localStorage.getItem("access_token")) {
            localStorage.clear();
            navigate("/onboarding/1?plan=hardware", { state: { from: 'hardware' } });
            return;
        }
        setLoading(true);
        try {
            const token = localStorage.getItem('access_token');
            //@ts-ignore
            const decodedToken = decodeToken(token);
            //@ts-ignore
            const email = decodedToken?.email;
            const { data }: AxiosResponse<any, any> = await api.post(
                "chargebee/checkout-hardware",
                {
                    redirect_url: `${process.env.REACT_APP_URL}/account/upgrade-success?plan=hardware`,
                    email
                },
            );
            navigate(`/account/checkout`, {
                state: {
                    url: data.hosted_page.url,
                    additionalText: "Hardware - $300",
                    title: "REC-1"
                }
            });
        } catch (err) {
            console.error(err)
            setLoading(false);
        }
    };
    const handlePurchaseAction = () => {
        buyHardwarePlan()
    };


    return (
        <>
            {loading && (
                <div className="landing-loader">
                    <div className="left-loader-part"></div>
                    <svg id="Layer_1" x="0px" y="0px" viewBox="0 0 1366 22">
                        <polygon
                            className="st0"
                            points="685.3,1 685,1 0,1 0,11 685,11 692,11 692.1,11 "
                        />
                        <polygon
                            className="st0"
                            points="699,11 692,11 698.7,21 699,21 1366,21 1366,11 "
                        />
                    </svg>
                    <div className="right-loader-part"></div>
                </div>
            )}
            <StyledLandingPageWrapper className="landing-page-wrapper">
                {localStorage.getItem("access_token") ? <Header /> :
                    <LandingNavbar visible={visible} ww={width} getDensity={() => { return handlePurchaseAction() }} />}
                <main style={{ maxWidth: 1360, margin: "auto" }}>

                    <StyledDensityHardwareContainer>

                        <div className="mt-[126px] mb-[146px]">
                            <Typography
                                className="density-hero-heading hardware-density-heading"
                                variant="h1"
                                component="h1"
                                sx={{
                                    background:
                                        "linear-gradient(182.83deg, rgba(218, 220, 240, 0.8) 12.57%, rgba(168, 171, 219, 0.5) 88.12%)",
                                    "-webkit-background-clip": {
                                        md: "text",
                                        xs: "text",
                                    },
                                    "-webkit-text-fill-color": {
                                        md: "transparent",
                                        xs: "transparent",
                                    },
                                }}
                            >
                                Density Hardware. <br />
                                Setup Guide
                            </Typography>
                        </div>
                    </StyledDensityHardwareContainer>

                    <section className="mb-[100px]">
                        <StyledLandingVideoWrapper ref={videoRef}>
                            <ModalVideo
                                channel="youtube"
                                isOpen={isOpen}
                                videoId="Ix7_3nIACpU"
                                youtube={{
                                    autoplay: 1,
                                    showinfo: 0,
                                    controls: 0,
                                }}
                                onClose={() => setOpen(false)}
                            />
                            <img className="landing-video-image" src={NewCoverImage} alt="" />

                            <button
                                className="landing-video-play-icon"
                                onClick={() => setOpen(true)}
                            >
                                <img
                                    role="presentation"
                                    src={PlayIcon}
                                    onClick={() => setOpen(true)}
                                    alt=""
                                />
                            </button>
                            <StyledLandingBoldParagraph
                            >
                                {"Density explained in 90 seconds"}
                            </StyledLandingBoldParagraph>
                        </StyledLandingVideoWrapper>
                    </section>


                    <section className=" md:mt-24">
                        <InfoPanel data={{ ...TEXTS.FIRST_INFO_PANEL }} className="bg-[#FFFFFF0D] pt-[79px] p-12 rounded-[30px]">
                            <img src={FullSetup} alt="the full setup" />
                        </InfoPanel>
                    </section>

                    <section className="mt-[38px] md:mt-24">
                        <div className="bg-[#FFFFFF0D] rounded-[30px] relative w-[100%] box-border flex flex-col justify-center items-center text-center pb-[84px] w-[100%]">
                            <h2 className="font-['PPRightGrotesk-Bold'] text-[34px] text-primary-purple leading-[27.42px] text-center py-[74px]">Tech rider</h2>
                            <div className="flex flex-col md:flex-row justify-between lg:px-[104px] w-[100%] gap-12 md:gap-0">
                                <div className="flex flex-col gap-6 text-center md:text-left text-base-gray mx-auto">
                                    <img src={Mixer} alt="REC setup tech rider" className="w-[250.77px]" />
                                    <p className="text-primary-purple">V10, 900, A9</p>
                                    <div className="flex gap-2 w-[100%] justify-center md:justify-start">
                                        <img src={Info} className="w-[24px]" />
                                        <p>No Xone.</p>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-6 text-center md:text-left text-base-gray mx-auto">
                                    <img src={DJBooth} alt="REC setup tech rider" className="w-[190.15px]" />
                                    <p className="text-primary-purple">3000, 2000nxs2, 1000, XZ</p>
                                    <div className="flex gap-2">
                                        <img src={Info} className="w-[24px]" />
                                        <p>No Traktor or Serato.</p>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-6 text-left text-base-gray text-[14px] max-w-[273px] mx-auto">
                                    <div className="w-[273px]">
                                        <img src={MultiPort} alt="REC setup tech rider" />
                                    </div>
                                    <div>
                                        <p className="text-primary-purple">8 port network switch</p>
                                        <p>4 CDJs + Mixer will fill a 5 port Switch.</p>
                                    </div>
                                    <p>If the artist plans on setting up the recorder as they get on stage, the network switch and power outlet need to be easily accessible.</p>
                                </div>
                            </div>
                        </ div>
                    </section>

                    <div className="my-[100px]">
                        <Typography
                            className="density-hero-heading hardware-density-heading"
                            variant="h1"
                            component="h1"
                            sx={{
                                background:
                                    "linear-gradient(182.83deg, rgba(218, 220, 240, 0.8) 12.57%, rgba(168, 171, 219, 0.5) 88.12%)",
                                "-webkit-background-clip": {
                                    md: "text",
                                    xs: "text",
                                },
                                "-webkit-text-fill-color": {
                                    md: "transparent",
                                    xs: "transparent",
                                },
                            }}
                        >
                            How to setup
                        </Typography>
                    </div>

                    <section className="mt-[38px] md:mt-24">
                        <div className="flex flex-wrap gap-4 ">

                            <div className="bg-[#FFFFFF0D] rounded-[30px] relative w-[100%] md:w-[calc(50%-0.5rem)] box-border flex flex-col justify-center items-center text-center">
                                <div className="mt-[168px] flex justify-center">
                                    <img src={RectSetupStepOne} alt="REC setup step one" className="w-[344px]" />
                                </div>
                                <Step step={"1"} className="absolute top-[32px] left-[32px]" />

                                <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px] text-center pt-[60px]">
                                    Ensure the power cable is
                                </h3>
                                <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px]">
                                    pushed all the way into the device.
                                </h3>
                                <p className="pt-4 text-base-gray text-[12px]">
                                    If it is partially pushed in, the device will overheat and shut off.
                                </p>
                                <p className="text-base-gray text-[12px] mb-[89px]">
                                    After you ensure everything is in place, to push the power button.
                                </p>
                            </div>

                            <div className="bg-[#FFFFFF0D] rounded-[30px] relative w-[100%] md:w-[calc(50%-0.5rem)] box-border flex flex-col justify-center items-center text-center">
                                <div className="pt-[139px] flex justify-center">
                                    <img src={RectSetupStepTwo} alt="REC setup step two" className="w-[240px]" />
                                </div>
                                <Step step={"2"} className="absolute top-[32px] left-[32px]" />

                                <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px] text-center pt-[50px]">
                                    When the device is
                                </h3>
                                <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px]">
                                    ready, it will say:
                                </h3>
                                <span className="pt-4 text-base-gray text-[12px] mb-[89px] flex gap-1">
                                    <p>Mixer:</p>
                                    <p className="text-primary-purple">Disconnected</p>
                                    <p></p>
                                    <p>Decks:  </p>
                                    <p className="text-primary-purple">Disconnected</p>
                                </span>
                            </div>

                            <div className="bg-[#FFFFFF0D] rounded-[30px] relative w-[100%] md:w-[calc(50%-0.5rem)] box-border flex flex-col justify-center items-center text-center">
                                <div className="pt-[139px] flex justify-center">
                                    <img src={RectSetupStepThree} alt="REC setup step three" className="w-[403.17px]" />

                                </div>
                                <Step step={"3"} className="absolute top-[32px] left-[32px]" />

                                <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px] text-center pt-[50px]">
                                    Connect the Rec-1 via ethernet
                                </h3>
                                <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px]">
                                    to the network switch.
                                </h3>

                                <span className="pt-4 text-base-gray text-[12px]">
                                    It can take fourteen seconds to resolve on the network.
                                </span>
                                <span className="text-base-gray text-[12px] mb-[89px] flex gap-1">
                                    <p>When it’s done it will say</p>
                                    <p className="text-primary-purple">Decks: Connected</p>
                                </span>
                            </div>

                            <div className="bg-[#FFFFFF0D] rounded-[30px] relative w-[100%] md:w-[calc(50%-0.5rem)] box-border flex flex-col justify-center items-center text-center">
                                <div className="pt-[139px] flex justify-center">
                                    <img src={RectSetupStepFour} alt="REC setup step four" className="w-[190.15px]" />
                                </div>
                                <Step step={"4"} className="absolute top-[32px] left-[32px]" />

                                <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px] text-center pt-[50px]">
                                    Deck settings
                                </h3>

                                <span className="pt-4 text-base-gray text-[12px] flex gap-1">
                                    <p>Continuous mode:</p>
                                    <p className="text-primary-purple"> Off</p>
                                </span>
                                <span className="text-base-gray text-[12px] mb-[89px] flex gap-1">
                                    <p>Auto-cue: </p>
                                    <p className="text-primary-purple">On</p>
                                </span>
                            </div>

                            <div className="bg-[#FFFFFF0D] rounded-[30px] relative w-[100%] box-border flex flex-col md:flex-row items-center text-center md:text-left">

                                <div className="mx-[66px]">
                                    {width < 539 ? (
                                        <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px] pt-[150px] md:pt-[50px] text-left">
                                            Connect the USB to the top LEFT
                                            of the mixer and press  the MIDI
                                            button directly underneath.
                                        </h3>
                                    ) : (
                                        <>
                                            <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px] pt-[150px] md:pt-[50px]">
                                                Connect the USB to the top LEFT
                                            </h3>
                                            <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px]">
                                                of the mixer and press  the MIDI
                                            </h3>
                                            <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px]">
                                                button directly underneath.
                                            </h3>
                                        </>
                                    )}


                                    <ul className="list-disc max-w-[43ch] font-['Inter'] font-medium leading-[18px] tracking-[0.1px] !text-base-gray text-[12px] my-4 ml-8 text-left">
                                        <li>Move a fader to send midi data;</li>
                                        <li>The Rec-1 will say Mixer Connected;</li>
                                        <li>If the mixer gets turned off, or loses power, the midi button needs to be pressed again, or it will not record.</li>
                                    </ul>
                                </div>
                                <div className="pt-[82px] mb-[102px]  flex justify-center">
                                    <img src={RectSetupStepFive} alt="REC setup step five" className="w-[600px]" />

                                </div>
                                <Step step={"5"} className="absolute top-[32px] left-[32px]" />
                            </div>

                            <div className="bg-[#FFFFFF0D] rounded-[30px] relative w-[100%]">

                                <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px] pt-[174px] md:pt-[74px] mb-[55px] text-center">
                                    Capture the initial FX settings
                                </h3>

                                <div className="flex flex-col md:flex-row gap-8 w-[100%] justify-center text-center px-8">
                                    <p className="pt-4 text-base-gray text-[12px] max-w-[28ch] text-center m-auto md:m-0">
                                        Select HPF or LPF on the V10. Press the button even if it's already set to correct setting. The recorder doesn't know until the button is pressed.
                                    </p>
                                    <div className="flex -mb-[12px]">
                                        <img src={RectSetupStepSix} alt="REC setup step five" className="w-[700px]" />
                                    </div>
                                    <div className="flex flex-col">
                                        <span className="pt-4 text-base-gray text-[12px]">
                                            Turn the effects selector.
                                        </span>
                                        <span className="text-base-gray text-[12px]">
                                            The recorder doesn't know what
                                        </span>
                                        <span className="text-base-gray text-[12px] mb-[100px] md: mb-0">
                                            effect it's on until it's moved.
                                        </span>
                                    </div>
                                </div>
                                <Step step={"6"} className="absolute top-[32px] right-[32px]" />
                            </div>

                            <div className="bg-[#FFFFFF0D] rounded-[30px] relative w-[100%] box-border flex flex-col md:flex-row items-center text-center md:text-left">

                                <div className="mx-[66px] min-w-fit">
                                    {width < 768 ? (
                                        <>
                                            <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px] pt-[150px] md:pt-[50px]">
                                                When the screen displays
                                                “Decks and Mixer  Connected”,
                                                it’s ready to record.
                                            </h3>
                                            <p className="pt-4 text-base-gray text-[12px]">
                                                Data is only captured when something is happening on the
                                                Decks. The device can be left on until showtime without
                                                wasting space.
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px] pt-[150px] md:pt-[50px]">
                                                When the screen displays
                                            </h3>
                                            <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px] ">
                                                “Decks and Mixer  Connected”,
                                            </h3>
                                            <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px]">
                                                it’s ready to record.
                                            </h3>
                                            <div className="flex flex-col text-left">
                                                <span className="pt-4 text-base-gray text-[12px]">
                                                    Data is only captured when something is happening on the
                                                </span>
                                                <span className="text-base-gray text-[12px]">
                                                    Decks. The device can be left on until showtime without
                                                </span>
                                                <span className="text-base-gray text-[12px] mb-6 md:mb-0">
                                                    wasting space.
                                                </span>
                                            </div>
                                        </>
                                    )}

                                </div>
                                <div className="pt-[82px] mb-[102px]  flex justify-center w-[100%]">
                                    <img src={RectSetupStepSeven} alt="REC setup step seven" className="w-[360px]" />
                                </div>
                                <Step step={"7"} className="absolute top-[32px] left-[32px]" />
                            </div>

                            <div className="bg-[#FFFFFF0D] rounded-[30px] relative w-[100%] box-border flex flex-col-reverse md:flex-row items-center text-center md:text-left">
                                <div className="md:pt-[134px] flex justify-center w-[100%]">
                                    <img src={RectSetupStepEight} alt="REC setup step eight" className="w-[603px]" />
                                </div>
                                <div className="md:mr-[135px] min-w-fit">
                                    <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px] pt-[150px] md:pt-[50px]">
                                        Press and hold the button to
                                    </h3>
                                    <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px] ">
                                        power down the device
                                    </h3>

                                    <div className="flex flex-col mb-10 md:mb-0">
                                        <span className="pt-4 text-base-gray text-[12px]">
                                            Pulling out the power cord will eventually damage the
                                        </span>
                                        <span className="text-base-gray text-[12px]">
                                            device. Do this once per day. Letting the device run for
                                        </span>
                                        <span className="text-base-gray text-[12px]">
                                            days may cause recording errors.
                                        </span>
                                    </div>
                                </div>

                                <Step step={"8"} className="absolute top-[32px] right-[32px]" />
                            </div>



                            <div className="bg-[#FFFFFF0D] rounded-[30px] relative w-[100%] box-border flex flex-col md:flex-row items-center text-center md:text-left">

                                <div className="mx-[66px] min-w-fit">
                                    {width < 768 ? (
                                        <>
                                            <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px] pt-[150px] md:pt-[50px]">
                                                No audio is recorded,
                                                just the  metadata.
                                            </h3>
                                            <p className="pt-4 text-base-gray text-[12px]">
                                                When opening a set, the DJ points Density to
                                                their music folder, and the set is recreated.
                                            </p>
                                        </>
                                    ) : (<>
                                        <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px] pt-[150px] md:pt-[50px]">
                                            No audio is recorded,
                                        </h3>
                                        <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px] ">
                                            just the  metadata.
                                        </h3>
                                        <div className="flex flex-col mb-[100px] md:mb-0">
                                            <span className="pt-4 text-base-gray text-[12px]">
                                                When opening a set, the DJ points Density to
                                            </span>
                                            <span className="text-base-gray text-[12px]">
                                                their music folder, and the set is recreated.
                                            </span>
                                        </div>
                                    </>)}
                                </div>
                                <div className="pt-[82px] flex justify-end -mr-2 w-[100%]">
                                    <img src={RectSetupStepFinal} alt="REC setup step seven" className="w-[747px]" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <StyledDensityHardwareContainer>
                        <div className="my-[100px]">
                            <Typography
                                className="density-hero-heading hardware-density-heading"
                                variant="h1"
                                component="h1"
                                sx={{
                                    background:
                                        "linear-gradient(182.83deg, rgba(218, 220, 240, 0.8) 12.57%, rgba(168, 171, 219, 0.5) 88.12%)",
                                    "-webkit-background-clip": {
                                        md: "text",
                                        xs: "text",
                                    },
                                    "-webkit-text-fill-color": {
                                        md: "transparent",
                                        xs: "transparent",
                                    },
                                }}
                            >
                                Retrieving sets
                            </Typography>
                        </div>
                    </StyledDensityHardwareContainer>

                    <section className="mt-[38px] md:mt-24">
                        <div className="flex flex-wrap gap-4 ">

                            <div className="bg-[#FFFFFF0D] rounded-[30px] relative w-[100%] md:w-[calc(50%-0.5rem)] box-border flex flex-col text-center pb-[119px] md:pb-0">

                                <Step step={"1"} className="absolute bottom-[32px] left-[32px]" />

                                <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px] text-center pt-[50px]">
                                    At the end of the night plug a
                                </h3>
                                <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px]">
                                    USB stick into the Rec-1 to
                                </h3>
                                <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px] text-center">
                                    retrive the sets.
                                </h3>
                                <div className="flex justify-center my-8">
                                    <img src={RetrievingSetsStepOne} alt="Retriving sets step one" className="w-[180px]" />
                                </div>
                            </div>

                            <div className="bg-[#FFFFFF0D] rounded-[30px] relative w-[100%] md:w-[calc(50%-0.5rem)] box-border flex flex-col text-center pb-[119px] md:pb-0">

                                <Step step={"2"} className="absolute bottom-[32px] left-[32px]" />

                                <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px] text-center pt-[50px]">
                                    Do not remove the USB
                                </h3>
                                <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px]">
                                    until it says “Done”.
                                </h3>

                                <p className="pt-4 text-base-gray text-[12px]">
                                    The screen will let you know when it’s
                                </p>
                                <p className="text-base-gray text-[12px]">
                                    safe to remove the USB stick. Removing the USB
                                </p>
                                <p className="text-base-gray text-[12px]">
                                    before it says “Done” will damage the device.
                                </p>

                                <div className="flex justify-center my-8">
                                    <img src={RetrievingSetsStepTwo} alt="Retriving sets step two" className="w-[180px]" />
                                </div>
                            </div>

                            <div className="bg-[#FFFFFF0D] rounded-[30px] relative w-[100%] box-border flex flex-col md:flex-row items-center text-center md:text-left pt-[119px] pb-[154px]">
                                <div className="mx-[66px] min-w-fit">
                                    <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px] md:pt-[50px]">
                                        Open the Density software and
                                    </h3>
                                    <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl text-primary-purple leading-[27.42px] ">
                                        plug the USB into your computer.
                                    </h3>
                                    <ol className="list-decimal max-w-[45ch] font-['Inter'] text-[12px] font-medium leading-[18px] tracking-[0.1px] !text-base-gray text-[12px] my-4 ml-6">
                                        <li>The sets will automatically save to the computer.</li>
                                        <li>Click “Convert” to process your set. If the recording is long this can take time.</li>
                                        <li>If more than one DJ played, the sets will automatically split.</li>
                                    </ol>
                                </div>
                                <div className="flex justify-center w-[100%]">
                                    <img src={RetrievingSetsStepThree} alt="Retrieve sets step three" className="w-[434px]" />
                                </div>
                                <Step step={"3"} className="absolute bottom-[32px] left-[32px]" />
                            </div>


                            <div className="bg-[#FFFFFF0D] rounded-[30px] relative w-[100%] md:w-[calc(50%-0.5rem)] box-border flex flex-col text-center pb-[49px] md:pb-0">

                                <Step step={"4"} className="absolute bottom-[32px] left-[32px]" />

                                <h3 className=" font-['PPRightGrotesk-Bold'] max-w-[18ch] text-2xl text-primary-purple leading-[27.42px] text-center pt-[50px] mx-auto">
                                    When opening a set, the DJ points Density to their music folder, and the set is recreated.
                                </h3>
                                <div className="flex justify-center mt-8 mb-[99px]">
                                    <img src={RetrievingSetsStepFour} alt="Retrieving sets step four" className="w-[156px]" />
                                </div>
                            </div>


                            <div className="bg-[#FFFFFF0D] rounded-[30px] relative w-[100%] md:w-[calc(50%-0.5rem)] box-border flex flex-col text-center pb-[49px] md:pb-0">

                                <Step step={"5"} className="absolute bottom-[32px] left-[32px]" />

                                <h3 className=" font-['PPRightGrotesk-Bold'] max-w-[20ch] text-2xl text-primary-purple leading-[27.42px] text-center pt-[50px] mx-auto">
                                    If you don’t have the music files, you can easily share the set with the DJ.
                                </h3>
                                <div className="flex justify-center mt-8 mb-[99px]">
                                    <img src={RetrievingSetsStepFive} alt="Retrieving sets step five" className="w-[116px]" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="my-[100px]">
                        <Typography
                            className="density-hero-heading hardware-density-heading"
                            variant="h1"
                            component="h1"
                            sx={{
                                background:
                                    "linear-gradient(182.83deg, rgba(218, 220, 240, 0.8) 12.57%, rgba(168, 171, 219, 0.5) 88.12%)",
                                "-webkit-background-clip": {
                                    md: "text",
                                    xs: "text",
                                },
                                "-webkit-text-fill-color": {
                                    md: "transparent",
                                    xs: "transparent",
                                },
                            }}
                        >
                            Safe for Artists
                        </Typography>
                    </div>

                    <section className="mt-[38px] md:mt-24">
                        <InfoShowcase data={{ ...TEXTS.SAFE_FOR_ARTISTS }} className="w-[100%] relative" customClassName={"bg-[#FFFFFF0D]"}>
                            <div className="flex justify-center pt-10 pl-10 pt-16">
                                <img src={OpenTheSet} alt="Open the set" />
                            </div>
                        </InfoShowcase>

                    </section>

                    <div className="my-[100px]">
                        <Typography
                            className="density-hero-heading hardware-density-heading"
                            variant="h1"
                            component="h1"
                            sx={{
                                background:
                                    "linear-gradient(182.83deg, rgba(218, 220, 240, 0.8) 12.57%, rgba(168, 171, 219, 0.5) 88.12%)",
                                "-webkit-background-clip": {
                                    md: "text",
                                    xs: "text",
                                },
                                "-webkit-text-fill-color": {
                                    md: "transparent",
                                    xs: "transparent",
                                },
                            }}
                        >
                            FAQ
                        </Typography>
                    </div>

                    <div className="flex flex-col gap-4">
                        {

                            TEXTS.FAQ.map((faq, index) => {
                                return (
                                    <FaqCard key={index} {...faq} />
                                )
                            })
                        }
                    </div>

                    <section className="mt-[118px] mb-[18px] md:mt-24">
                        <div className="text-center">

                            <h3 className="font-['PPRightGrotesk-WideMedium'] text-2xl font-black leading-[27.42px] text-primary-purple">
                                Contact For Issues/Questions
                            </h3>
                            <p className="pt-4 font-['Inter'] text-[14px] font-medium leading-[24px] tracking-[0.1px] !text-base-gray text-[12px] mb-[118px]">
                                A real human DJ can help: <a href="mailto:support@density.one" className="!text-primary-purple">support@density.one</a>
                            </p>
                        </div>
                    </section>

                    <Footer />
                </main >
            </StyledLandingPageWrapper >

        </>
    );
};

export default RecSetupPage;
