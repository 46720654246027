import { InfoshowcaseProps } from "./types";
import whiteShadow from "../../../assets/white-shadow.png";
import React from "react";

const InfoShowcase = ({ data, children, customClassName, className = "" }: {
    data: InfoshowcaseProps;
    children: React.ReactNode | React.ReactNode[];
    customClassName?: any;
    className?: string;
}): JSX.Element => {
    const { heading, title, description, rtl, shadow } = data;
    let innerContainerClass = `flex flex-col ${rtl ? "md:flex-row-reverse" : "md:flex-row"} justify-center items-center`;
    const hasDescriptionSideElements = React.Children.count(children) > 1;
    const childrenArray = React.Children.toArray(children);

    return (
        <div className={className}>
            {heading &&
                <div className="pb-5">
                    <h2 className="font-['PPRightGrotesk-Bold'] font-extrabold text-[32px] font-black leading-[38.85px] text-primary-purple text-center">{heading}</h2>
                </div>
            }
            <div className={`bg-[#121212] rounded-[30px] pt-19} ${customClassName}`} >
                <div className={innerContainerClass}>
                    <div className="md:flex-1 md:basis-1/3 p-8 relative">
                        {shadow &&
                            <div>
                                <img src={whiteShadow} className="absolute top-[-30px] left-[-30px] w-[100%]" />
                            </div>
                        }
                        <p className="font-['PPRightGrotesk-Bold'] text-[34px] font-black leading-[38.85px] text-primary-purple">{title}</p>
                        <p className="font-['Inter'] text-[14px] font-medium leading-[18px] tracking-[0.1px] py-5 !text-base-gray">{
                            description
                        }</p>
                        <div className="">
                            {hasDescriptionSideElements && childrenArray[1]}
                        </div>
                    </div>
                    <div className="md:flex-1 md:basis-2/3">
                        {hasDescriptionSideElements ? childrenArray[0] : children}
                    </div>
                </div>
            </div>
        </div >
    );
}

export default InfoShowcase;