import React from "react";
import { Box, Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import ModalVideo from "react-modal-video";
import { StyledAuthButton } from "../../components/micro/micro.styled";
import LandingNavbar from "../../components/LandingNavbar";

import Light from "../../assets/light.svg";
import Sign from "../../assets/sign.svg";
import LightRight from "../../assets/light-right.png";
import LightCentral from "../../assets/light-central.png";
import TextLight from "../../assets/text-light.png";
import DJAirplane from "../../assets/dj in an airplane.webp";
import DJAirplaneMobile from "../../assets/dj-airplane-mobile.webp";
import CoverImage from "../../assets/cover-image.webp";
import PlayIcon from "../../assets/Pause Controls.svg";
import DJImage from "../../assets/dj image.webp";
import DJImageMobile from "../../assets/dj image-mobile.png";

import CreateASet from "../../components/Landing/CreateASet";
import HeroWithText from "../../components/Landing/HeroWithText";
import DensityFeatures from "../../components/Landing/DensityFeatures";
import MasterSet from "../../components/Landing/MasterSet";

import {
  StyledLandingBackgroundTopEffect,
  StyledLandingBoldParagraph,
  StyledLandingHeroSection,
  StyledLandingPageContainer,
  StyledLandingPageWrapper,
  StyledLandingVideoWrapper,
} from "./landing.styled";
import useWindowDimensions from "../../utils/hooks/windowDimensionsHook";
import EditYourSet from "../../components/Landing/EditYourSet";
import StartYourFreeTrial from "../../components/Landing/StartYourFreeTrial";
import Footer from "../../components/Landing/Footer";
import { useNavigate } from "react-router-dom";
import { text } from "stream/consumers";
import { useDispatch } from "react-redux";

const LandingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const lightLeftRef = useRef<HTMLDivElement>(null);
  const lightRightRef = useRef<HTMLDivElement>(null);

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const contentLoaded = Boolean(localStorage.getItem("content-loaded"));
  const [loading, setLoading] = useState(!contentLoaded);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, [isOpen]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      if (currentScrollPos > prevScrollPos) {
        setVisible(true);
      } else {
        setVisible(false);
      }

      setPrevScrollPos(currentScrollPos);
    };
    window.addEventListener("scroll", handleScroll, true);

    if (loading) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1500);

      setTimeout(() => {
        setLoading(false);
        localStorage.setItem("content-loaded", "true");
      }, 2500);
    }

    dispatch({ type: "LANDING_VISITED", payload: { visit: true } });

    return () => window.removeEventListener("scroll", handleScroll, true);
  }, []);

  return (
    <>
      {loading && (
        <div className="landing-loader">
          <div className="left-loader-part"></div>
          <svg id="Layer_1" x="0px" y="0px" viewBox="0 0 1366 22">
            <polygon
              className="st0"
              points="685.3,1 685,1 0,1 0,11 685,11 692,11 692.1,11 "
            />
            <polygon
              className="st0"
              points="699,11 692,11 698.7,21 699,21 1366,21 1366,11 "
            />
          </svg>
          <div className="right-loader-part"></div>
        </div>
      )}
      <LandingNavbar ww={width} visible={visible} />
      <StyledLandingPageWrapper className="landing-page-wrapper">
        <div className="relative">
          <StyledLandingBackgroundTopEffect src={Light} alt="" />
          <StyledLandingPageContainer>
            <Typography
              className="density-hero-heading"
              variant="h1"
              component="h1"
              sx={{
                background: {
                  md: "linear-gradient(182.08deg, #FFFFFF -35.04%, rgba(112, 117, 196, 0.25) 164.5%)",
                  xs: "linear-gradient(192.02deg, #FFFFFF -12.23%, rgba(112, 117, 196, 0.1) 125.86%)",
                },
                "-webkit-background-clip": {
                  md: "text",
                  xs: "text",
                },
                "-webkit-text-fill-color": {
                  md: "transparent",
                  xs: "transparent",
                },
              }}
            >
              Density allows you to record a DJ set and edit it after
            </Typography>
          </StyledLandingPageContainer>
        </div>
        <StyledLandingPageContainer>
          <StyledLandingHeroSection>
            <StyledAuthButton
              variant="contained"
              color="primary"
              className="density-hero-button"
              onClick={() => navigate("/waitlist")}
              sx={{
                width: {
                  md: 200,
                  xs: 250,
                },
                padding: {
                  xs: "10px 16px",
                  md: "15px",
                },
                backgroundColor: "#c1c5dd",
                mt: "50px",
                mb: "0px",
              }}
            >
              Download Density
            </StyledAuthButton>
          </StyledLandingHeroSection>
          <StyledLandingVideoWrapper>
            <ModalVideo
              channel="youtube"
              isOpen={isOpen}
              videoId="Ix7_3nIACpU"
              youtube={{
                autoplay: 1,
                showinfo: 0,
                controls: 1,
              }}
              onClose={() => setOpen(false)}
            />
            <img className="landing-video-image" src={CoverImage} alt="" />

            <button
              className="landing-video-play-icon"
              onClick={() => setOpen(true)}
            >
              <img
                role="presentation"
                src={PlayIcon}
                onClick={() => setOpen(true)}
                alt=""
              />
            </button>
            <StyledLandingBoldParagraph
            //         sx={{
            //           margin: {xs: 0},
            // fontSize: 12px;
            // text-align: center;
            // width: 100%;
            // align-self: center;
            //         }}
            >
              Density explained in 90 seconds
            </StyledLandingBoldParagraph>
          </StyledLandingVideoWrapper>
        </StyledLandingPageContainer>
        <div style={{ position: "relative" }}>
          <StyledLandingPageContainer>
            <HeroWithText
              bgImage={width < 769 ? DJImageMobile : DJImage}
              title="Record your set using DJ hardware"
              text="Density connects to club standard DJ decks to record a set.
                Rather than recording audio, Density records the data: every
                cue, loop, and knob twist."
              subtitle="DENSITY HARDWARE"
              direction="right"
              imageGradient={`${
                width < 769
                  ? "linear-gradient(125.64deg, rgba(0, 0, 0, 0.81) 44.06%, rgba(0, 0, 0, 0.306653) 86.22%, rgba(0, 0, 0, 0.09) 100%);"
                  : "linear-gradient(-90deg, rgba(0, 0, 0, 0.81) 30.81%, rgba(0, 0, 0, 0.306653) 81.65%, rgba(0, 0, 0, 0.09) 98.28%)"
              }`}
              textGradient={`${
                width < 769
                  ? "linear-gradient(90.85deg, #FFFFFF -11.87%, rgba(112, 117, 196, 0.25) 112.71%)"
                  : "linear-gradient(92.01deg, #FFFFFF -30.27%, #1F1E1E 98.75%)"
              }`}
            />
          </StyledLandingPageContainer>
          <img
            src={LightCentral}
            alt=""
            style={{
              position: "absolute",
              left: "-50%",
              zIndex: 1,
              objectFit: "none",
            }}
          />
        </div>

        <StyledLandingPageContainer>
          <EditYourSet
            lightLeftRef={lightLeftRef}
            lightRightRef={lightRightRef}
          />
        </StyledLandingPageContainer>
        <div style={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              right: "-50%",
              zIndex: {
                xs: -1,
                md: 1,
              },
              objectFit: "none",
              pointerEvents: "none",
            }}
          >
            <img
              src={LightRight}
              alt=""
              style={{ width: "100%", pointerEvents: "none" }}
            />
          </Box>

          <StyledLandingPageContainer>
            <HeroWithText
              bgImage={width < 769 ? DJAirplaneMobile : DJAirplane}
              title="Works as a standalone app"
              text="If you don’t have access to DJ decks, Density works as as
                standalone Mac desktop app. You can point and click your way to
                a refined studio set without the need for hardware."
              subtitle="DESKTOP SOFTWARE"
              direction={width < 769 ? "right" : "left"}
              imageGradient={`${
                width < 769
                  ? "linear-gradient(178.12deg, rgba(3, 3, 5, 0.95) -0.07%, rgba(4, 4, 5, 0.76) 35.01%, rgba(0, 0, 0, 0.095) 100%)"
                  : "linear-gradient(99.5deg, rgba(3, 3, 5, 0) 13.72%, rgba(4, 4, 5, 0.7125) 33.58%, rgba(0, 0, 0, 0.095) 60.95%)"
              }
            `}
              textGradient="linear-gradient(93.49deg, #23292B -16.65%, #FFFFFF 137.83%)"
            />
          </StyledLandingPageContainer>
        </div>
        <div style={{ position: "relative" }}>
          <img
            src={TextLight}
            alt=""
            style={{
              position: "absolute",
              zIndex: 1,
              maxWidth: "80%",
              left: "10%",
              top: "40%",
            }}
          />
          <StyledLandingPageContainer>
            {width > 769 && <CreateASet />}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <Typography
                variant="h1"
                component="h1"
                className="density-hero-heading"
                sx={{
                  background: {
                    xs: "linear-gradient(186.71deg, #FFFFFF -17.63%, rgba(112, 117, 196, 0.1) 120.39%)",
                    md: "linear-gradient(182.07deg, #FFFFFF -48.77%, rgba(112, 117, 196, 0.25) 159.45%)",
                  },
                  "-webkit-background-clip": {
                    xs: "text",
                    md: "text",
                  },
                  "-webkit-text-fill-color": {
                    xs: "transparent",
                    md: "transparent",
                  },
                  maxWidth: {
                    xs: "280px !important",
                    md: "800px !important",
                  },
                }}
              >
                Designed to make set editing painless
              </Typography>
              {width > 769 && (
                <StyledAuthButton
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/waitlist")}
                  sx={{
                    width: 200,
                    backgroundColor: "#c1c5dd",
                    mt: "50px",
                    mb: 0,
                  }}
                >
                  Download Density
                </StyledAuthButton>
              )}
            </Box>
          </StyledLandingPageContainer>
        </div>
        <div style={{ position: "relative" }}>
          <StyledLandingPageContainer>
            <DensityFeatures width={width} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <Typography
                variant="h1"
                component="h1"
                className="density-hero-heading"
                sx={{
                  maxWidth: "800px",
                  background:
                    width > 769
                      ? "linear-gradient(277.78deg, rgba(255, 255, 255, 0.75) 22.42%, rgba(112, 117, 196, 0.1) 90.24%)"
                      : "linear-gradient(269.79deg, #FFFFFF -6.82%, rgba(112, 117, 196, 0.1) 106.81%)",
                  "-webkit-background-clip": "text",
                  "-webkit-text-fill-color": "transparent",
                }}
              >
                Master the set <br /> for release
              </Typography>
            </Box>
            <Box
              sx={{
                position: "absolute",
                right: "-50%",
                zIndex: {
                  xs: -1,
                  md: 1,
                },
                objectFit: "none",
                pointerEvents: "none",
              }}
            >
              <img
                src={LightRight}
                alt=""
                style={{ width: "100%", pointerEvents: "none" }}
              />
            </Box>
            <MasterSet width={width} />
            <StartYourFreeTrial width={width} />
          </StyledLandingPageContainer>
        </div>
        <Footer />
      </StyledLandingPageWrapper>
    </>
  );
};

export default LandingPage;
