import { useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { FaqCardProps } from "./types";

const FaqCard = ({ question, answer }: FaqCardProps) => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleAnswer = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="bg-[#FFFFFF0D] rounded-[30px] py-8 px-16 relative">
            <div className="flex justify-between items-start">
                <h3 className="font-['PPRightGrotesk-WideMedium'] text-2xl font-black leading-[27.42px] text-left text-primary-purple">
                    {question}
                </h3>
                <button
                    onClick={toggleAnswer}
                    className="text-primary-purple font-bold"
                >
                    {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </button>
            </div>
            {typeof answer === "string" ? (
                <p className="pt-4 font-['Inter'] text-[14px] font-medium leading-[24px] tracking-[0.1px] !text-base-gray">
                    {answer}
                </p>
            ) : (
                answer.map((item, index) => (
                    <p key={index} className="pt-4 font-['Inter'] text-[14px] font-medium leading-[24px] tracking-[0.1px] !text-base-gray">
                        {item}
                    </p>
                ))  
            )}
        </div>
    );
}

export default FaqCard;