import StepShape from '../../../assets/stepShape.svg';

const Step = ({ step, className = "" }: { step: string, className?: string }) => {
    return (
        <div className={`flex items-center justify-center ${className}`}>
            <div className='relative w-[84px] h-[84px] flex items-center justify-center'>
                <img className="absolute min-w-[82px] h-[82px]" src={StepShape} alt={`Step-${step}`} />
                <p className="font-['PPRightGrotesk-WideMedium'] text-[25.6px] rotate-[6.86deg]">{step}</p>
            </div>
        </div>
    )
}

export default Step;